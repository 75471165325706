<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <input matInput [disabled]="isDisabled" [maxLength]="maxlength" [type]="isPasswordVisible ? 'text' : 'password'"
           [ngModel]="value" (ngModelChange)="setValue($event)" (keydown)="onKeyDown()" [required]="isRequired"
           (blur)="onBlur()" [errorStateMatcher]="errorStateMatcher" />

    <button *ngIf="visibilityEnabled && value" mat-icon-button matSuffix type="button"
            tabindex="-1" (click)="togglePasswordVisibility()">
        <mat-icon>{{ isPasswordVisible ? "visibility" : "visibility_off" }}</mat-icon>
    </button>

    <button *ngIf="clearEnabled && value" mat-icon-button matSuffix type="button" (click)="setValue(null)">
        <mat-icon>clear</mat-icon>
    </button>

    <ng-content></ng-content>
</mat-form-field>
