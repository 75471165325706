import { Component, EventEmitter, Input, OnInit, Optional, Output, Self, TemplateRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from '@core/custom-error-state-matcher';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-select-field',
    templateUrl: './select-field.component.html',
    styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() clearEnabled: boolean = false;
    @Input() hasEmptyOption: boolean = true;
    @Input() inline: boolean = false;
    @Input() label: string;
    @Input() searchEnabled: boolean = false;

    @Input() separator: string = ',';
    @Input() multiple: boolean = false;

    @Input() displayTemplate: TemplateRef<any>;
    @Input() optionHeaderTemplate: TemplateRef<any>;
    @Input() optionTemplate: TemplateRef<any>;
    @Input() triggerTemplate: TemplateRef<any>;

    @Input() set options(values: any[]) {
        this._options = values;

        this.activeOption = this.options.find(t => this.extractValue(t) === this.value) || this.value;
        this._activeOptionJson = JSON.stringify(this.activeOption);

        this.setDisplayValue();

        this.searching = false
    }

    @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

    searchControl: FormControl = new FormControl()
    value: any | any[];
    activeOption: any | null = null;
    displayValue: any | any[];
    isDisabled: boolean;
    isRequired: boolean;
    searching: boolean = false;

    get options(): any[] {
        return this._options;
    }

    readonly errorStateMatcher = new CustomErrorStateMatcher(this.parent);

    private _activeOptionJson: string | null = null;
    private _options: any[] = [];

    private subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.setRequiredState();

        this.subscriptions.push(
            this.searchControl.valueChanges
                .pipe(debounceTime(500), distinctUntilChanged())
                .subscribe(value => {
                    this.searching = true;
                    this.searchChange.emit(value);
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    compare(optionValue: any, selectedValue: any): boolean {
        return optionValue?.id === selectedValue || optionValue === selectedValue;
    }

    setValue(value: any): void {
        this.writeValue(value);
        this.onChange(this.value);
    }

    writeValue(value: any): void {
        this.value = Array.isArray(value)
            ? value.map(this.extractValue)
            : this.extractValue(value);

        this.activeOption = this.options.find(t => this.extractValue(t) === this.value) || this.value;
        this._activeOptionJson = JSON.stringify(this.activeOption);

        this.setDisplayValue();
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    protected isActiveOption(option: any): boolean {
        return this._activeOptionJson == JSON.stringify(option);
    }

    protected onClear(event: Event): void {
        event.stopPropagation();

        this.writeValue(this.multiple ? [] : null);
        this.onChange(this.value);
    }

    private setDisplayValue(): void {
        this.displayValue = Array.isArray(this.value)
            ? this.options.filter(t => this.value.includes(this.extractValue(t)))
            : this.activeOption;
    }

    private extractValue(value: any): any {
        return value?.id ?? value;
    }

    private onChange = (value: any) => { };
    private onTouch = () => { };
}
