export class ServiceDate {
    year: number;
    month: number;

    constructor(year: number, month: number);
    constructor(value: string);
    constructor(yearOrValue: number | string, month?: number) {
        if (typeof yearOrValue === 'number' && month !== undefined) {
            if (yearOrValue < 0)
                throw new Error("Year must be a positive integer.");

            if (month < 1 || month > 12)
                throw new Error("Month must be between 1 and 12.");

            this.year = yearOrValue;
            this.month = month;
        } else if (typeof yearOrValue === 'string') {
            const parts = yearOrValue.split("/");

            if (parts.length != 2 || !Number.isInteger(parseInt(parts[0])) || !ServiceDate.tryParseMonth(parts[1]))
                throw new Error("Value is not a valid management data.");

            this.year = parseInt(parts[0]);
            this.month = ServiceDate.tryParseMonth(parts[1]);
        }
    }

    static isValid(yearOrValue: number | string, month?: number): boolean {
        if (typeof yearOrValue === 'number' && month !== undefined) {
            if (yearOrValue < 0)
                return false;

            if (month < 1 || month > 12)
                return false;

        } else if (typeof yearOrValue === 'string') {
            const parts = yearOrValue.split("/");

            if (parts.length != 2 || !Number.isInteger(parseInt(parts[0])) || !ServiceDate.tryParseMonth(parts[1]))
                return false;
        }

        return true;
    }

    toString(): string {
        return `${this.year}/${ServiceDate.convertMonthToRoman(this.month)}`;
    }

    static convertMonthToRoman(month: number): string {
        switch (month) {
            case 1: return "I";
            case 2: return "II";
            case 3: return "III";
            case 4: return "IV";
            case 5: return "V";
            case 6: return "VI";
            case 7: return "VII";
            case 8: return "VIII";
            case 9: return "IX";
            case 10: return "X";
            case 11: return "XI";
            case 12: return "XII";
            default: return "";
        }
    }

    private static tryParseMonth(value: string): number {
        if (Number.isInteger(parseInt(value)))
            return Number(value)

        return ServiceDate.tryParseRomanToMonth(value)
    }

    private static tryParseRomanToMonth(value: string): number {
        switch (value.toUpperCase()) {
            case "I": return 1;
            case "II": return 2;
            case "III": return 3;
            case "IV": return 4;
            case "V": return 5;
            case "VI": return 6;
            case "VII": return 7;
            case "VIII": return 8;
            case "IX": return 9;
            case "X": return 10;
            case "XI": return 11;
            case "XII": return 12;
            default: return 0;
        }
    }
}
