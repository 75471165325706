import { Component, Input, Optional, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
    selector: 'app-number-edit-field',
    templateUrl: './number-edit-field.component.html'
})
export class NumberEditFieldComponent implements ControlValueAccessor {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() clearEnabled: boolean = false;
    @Input() decimalPlaces: number | null = null;
    @Input() displayClass: string = 'valbox';
    @Input() displayTemplate: TemplateRef<any>;
    @Input() inline: boolean = false;
    @Input() integerOnly: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() label: string;
    @Input() positiveOnly: boolean = false;

    displayValue: number | null;

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    setValue(value: number | null): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: number | null): void {
        this.displayValue = value;
    }

    registerOnChange(fn: (value: number | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private onChange = (value: number | null) => { };
    private onTouch = () => { };
}
