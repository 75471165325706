import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TableSettingsComponent } from '../table-settings/table-settings.component';
import { first } from 'rxjs';
import { TableComponent } from '../table/table.component';

const EDIT_DIALOG_WIDTH: string = '700px';

@Component({
    selector: 'app-table-wrapper',
    templateUrl: './table-wrapper.component.html',
    styleUrls: ['./table-wrapper.component.scss']
})
export class TableWrapperComponent implements AfterContentInit {
    constructor(private readonly dialog: MatDialog) { }

    @ContentChild(TableComponent, { static: true }) table: TableComponent;

    @Input() disableSettings: boolean = false;

    ngAfterContentInit(): void {
        if (!this.table)
            throw new Error('Incorrect use of table wrapper. Missing table component content.');
    }

    /**
     * Open table settings dialog.
     */
    protected openSettings(): void {
        const dialogRef = this.dialog.open(TableSettingsComponent, {
            data: this.table,
            width: EDIT_DIALOG_WIDTH
        });

        dialogRef.afterClosed()
            .pipe(first())
            .subscribe((success) => {
                // TODO
            });
    }
}
