import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Menu } from '../../../data-access/models/menu';

@Component({
    selector: 'app-sub-header-menu',
    templateUrl: './sub-header-menu.component.html',
    styleUrls: ['./../sub-header.component.scss', '../../header.component.scss']
})
export class SubHeaderMenuComponent {
    @Input() menu: Menu;
    @Input() colorName: string;

    @Output() toggleMenu: EventEmitter<Menu> = new EventEmitter<Menu>();
}
