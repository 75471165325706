import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from '@shared/data-access/app/app.service';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
    constructor(private app: AppService) { }

    @Input() minLength: number = 3;
    @Input() label: string;
    @Input() value: string;

    @Output() changed: EventEmitter<string> = new EventEmitter();

    readonly searchInput = new UntypedFormControl('');

    ngOnInit() {
        if (!this.label) {
            this.label = this.app.translate('common.searchPlaceholder');
        }

        this.label = this.label.replace('{{minLength}}', this.minLength.toString())

        this.searchInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
            if (!value || value.length >= this.minLength) {
                this.changed.emit(value);
            }
        });

        if (this.value) {
            this.searchInput.setValue(this.value);
        }
    }

    clear() {
        this.searchInput.setValue('', { emitEvent: false });
        this.changed.emit(null);
    }
}
