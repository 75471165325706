import { Component, Input, OnInit, Optional, Self, TemplateRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from '@core/custom-error-state-matcher';

@Component({
    selector: 'app-autocomplete-field',
    templateUrl: './autocomplete-field.component.html'
})
export class AutocompleteFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() clearEnabled: boolean = false;
    @Input() inline: boolean = false;
    @Input() label: string;
    @Input() maxlength: number = 1000;
    @Input() options: any[] = [];
    @Input() optionTemplate: TemplateRef<any>;

    value: string | null = null;
    isDisabled: boolean;
    isRequired: boolean;

    readonly errorStateMatcher = new CustomErrorStateMatcher(this.parent);

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: string): void {
        this.value = value;
        this.onChange(this.value);
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    private onChange = (value: string) => { };
    private onTouch = () => { };
}
