import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-value-field',
    templateUrl: './value-field.component.html'
})
export class ValueFieldComponent {
    @Input() displayClass: string = 'valbox';
    @Input() displayTemplate: TemplateRef<any>;
    @Input() inline: boolean = false;
    @Input() label: string | null = null;
    @Input() value: any = null;
}
