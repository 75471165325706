/**
 * Token is a value represented by a {<token_id>} text in another text.
 *
 * @example
 * // {name} is a token
 * const temaplte: string = 'My name is {name}';
 */
export class Token {
    constructor(private readonly tokenAsString: string) {
        this.id = this.tokenAsString.replace(/[{}]/g, "")
    }

    readonly id: string;

    private static readonly REGEX: RegExp = /({[^}]+})/g;

    /**
     * Extract tokens from a text.
     *
     * @param text Text from which to extract tokens.
     *
     * @returns A list of tokens.
     */
    static extractTokens(text: string): Token[] {
        let match;
        const tokens: Token[] = [];

        while ((match = Token.REGEX.exec(text)) !== null) {
            tokens.push(new Token(match[1]));
        }

        return tokens;
    }

    /**
     * Get token as a string.
     *
     * @returns Token as a string
     */
    asString(): string {
        return this.tokenAsString;
    }
}
