import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appRomanQuarter]'
})
export class RomanQuarterDirective {
    private romanNumerals = ['I', 'II', 'III', 'IV'];

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInputChange(event: KeyboardEvent) {
        const inputValue = this.el.nativeElement.value.toUpperCase();
        this.el.nativeElement.value = inputValue;
        if (!this.romanNumerals.includes(inputValue)) {
            this.el.nativeElement.value = inputValue.slice(0, -1);
        }
    }
}
