import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-hyperlink-edit',
    templateUrl: './edit.component.html'
})
export class HyperlinkEditComponent {
    constructor(
        private dialogRef: MatDialogRef<HyperlinkEditComponent>,
        @Inject(MAT_DIALOG_DATA)
        data?: {
            value: string;
        }
    ) {
        this.initialValue = this.hyperlink = data && data.value;
    }

    hyperlink: string;

    readonly pattern = '^[hH][tT][tT][pP][sS]?://.+';
    readonly initialValue: string;

    continue() {
        this.dialogRef.close(this.hyperlink);
    }

    cancel() {
        this.dialogRef.close(this.initialValue);
    }
}
