import { RestrictiveListType, Util } from "@zj/paka-client";
import { RestrictiveList } from "./restrictive-list";

export class BlackList<T> extends RestrictiveList<T> {

    readonly type: RestrictiveListType = RestrictiveListType.BlackList;

    allow(item: T) {
        const itemToRemove: T | undefined = this.find(t => this.comparator(t, item));

        if (itemToRemove)
            Util.removeItemFromArray(this, itemToRemove);
    }

    forbid(item: T) {
        this.push(item);
    }

    isAllowed(predicate: ((item: T) => boolean)): boolean {
        return this.find(predicate) ? false : true;
    }
}
