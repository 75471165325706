<header>
    <h1></h1>
</header>

<div class="container">
    <div class="row">
        <div class="col-lg-4 col-6" *ngFor="let menu of menus">
            <div class="box">
                <h2>{{ "nav." + menu.name | translate }}</h2>
                <div class="button-row">
                    <button *ngFor="let item of menu.getNavLinks()" mat-flat-button color="primary"
                        class="color-{{ menu.name }}" [routerLink]="['/' + item.url]">
                        {{ item.text || ("nav." + item.name | translate) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
