import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-checkbox-field',
    templateUrl: './checkbox-field.component.html',
    styleUrls: ['./checkbox-field.component.scss']
})
export class CheckboxFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() inline: boolean = false;
    @Input() label: string;

    value: boolean;
    isDisabled: boolean;
    isRequired: boolean;

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: boolean): void {
        this.value = value;
        this.onChange(this.value);
    }

    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    private onChange = (value: boolean) => { };
    private onTouch = () => { };
}
