import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from '@core/custom-error-state-matcher';
import { Moment } from 'moment';

@Component({
    selector: 'app-date-field',
    templateUrl: './date-field.component.html',
    styleUrls: ['./date-field.component.scss']
})
export class DateFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() clearEnabled: boolean = false;
    @Input() inline: boolean = false;
    @Input() label: string;

    value: Date | null = null;
    isDisabled: boolean;
    isRequired: boolean;

    readonly errorStateMatcher = new CustomErrorStateMatcher(this.parent);

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: Moment): void {
        this.value = value?.toDate() ?? null;
        this.onChange(this.value);
    }

    writeValue(value: Date): void {
        this.value = value;
    }

    registerOnChange(fn: (value: Date) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    private onChange = (value: Date) => { };
    private onTouch = () => { };
}
