import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export enum AlertType {
    Info = 'info',
    Success = 'success',
    Error = 'error',
    Warning = 'warning'
}

export class AlertOptions {
    /** Dialog body text */
    text: string;
    /** Alert type */
    type: AlertType = AlertType.Info;
    /** Dialog title */
    title?: string;
}

//export class Alert {
//  constructor(
//    public text: string,
//    public type: AlertType = AlertType.Info,
//    public title?: string
//  ) { }
//}

export class ConfirmOptions {
    /** Dialog body text */
    text: string;
    /** Dialog title */
    title?: string;
    /** "OK" button text */
    ok?: string;
    /** "Cancel" button text */
    cancel?: string;
}

//export class Confirm {
//  constructor(public options: ConfirmOptions) { }

//  private subj: Subject<boolean> = new Subject<boolean>();

//  listen(): Observable<boolean> {
//    return this.subj.asObservable();
//  }

//  result(result: boolean) {
//    this.subj.next(result);
//  }
//}

class AlertListener<TOptions> {
    constructor(public options: TOptions) { }

    private subj: Subject<boolean> = new Subject<boolean>();

    listen(): Observable<boolean> {
        return this.subj.asObservable();
    }

    result(result: boolean) {
        this.subj.next(result);
    }
}

export class Confirm extends AlertListener<ConfirmOptions> { }
export class Alert extends AlertListener<AlertOptions> { }

@Injectable({ providedIn: 'root' })
export class AlertService {
    private alertSubject = new Subject<Alert>();
    private confirmSubject = new Subject<Confirm>();
    private notifySubject = new Subject<string>();

    success(message: string, title?: string) {
        this.setAlert(message, AlertType.Success, title);
    }

    error(message: string, title?: string) {
        this.setAlert(message, AlertType.Error, title);
    }

    info(message: string, title?: string) {
        this.setAlert(message, AlertType.Info, title);
    }

    warning(message: string, title?: string) {
        this.setAlert(message, AlertType.Warning, title);
    }

    clearAlert() {
        this.setAlert(null);
    }

    getAlert(): Observable<Alert> {
        return this.alertSubject.asObservable();
    }

    notify(message: string) {
        this.notifySubject.next(message);
    }

    clearNotification() {
        this.notify(null);
    }

    getNotification(): Observable<string> {
        return this.notifySubject.asObservable();
    }

    alert(data: AlertOptions): Observable<boolean> {
        const alert = new Alert(data);
        this.alertSubject.next(alert);
        return alert.listen();
    }

    confirm(data: ConfirmOptions): Observable<boolean> {
        const confirm = new Confirm(data);
        this.confirmSubject.next(confirm);
        return confirm.listen();
    }

    getConfirm(): Observable<Confirm> {
        return this.confirmSubject.asObservable();
    }

    private setAlert(text: string, type: AlertType = AlertType.Info, title?: string) {
        if (text !== undefined) {
            this.alertSubject.next(new Alert({ text, type, title }));
        }
    }
}
