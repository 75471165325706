<h1 mat-dialog-title>
    {{ 'table.settings' | translate }}
</h1>

<div mat-dialog-content>
    <form #form="ngForm">
        <div class="table-wrapper">
            <table mat-table [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="drop($event)">
                <ng-container matColumnDef="index">
                    <td mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                </ng-container>

                <ng-container matColumnDef="drag">
                    <td mat-cell *matCellDef="let row">
                        <div class="drag-cell" *ngIf="!row.settings.locked">
                            <mat-icon>drag_indicator</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="label">
                    <td mat-cell *matCellDef="let row">{{ row.label | translate }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox color="primary" [checked]="row.settings.display" *ngIf="!row.settings.locked"
                            (change)="toggleColumn(row, $event)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; let i = index; columns: columnsToShow;" cdkDrag cdkDragLockAxis="y"
                    [class.disabled]="row.settings.locked" [cdkDragDisabled]="row.settings.locked">
                </tr>
            </table>
        </div>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
        {{ "btn.close" | translate }}
    </button>

    <button mat-flat-button color="accent" class="pull-right" (click)="restoreDefaults()">
        <mat-icon>settings_backup_restore</mat-icon>
        {{ "table.restoreDefaultSettings" | translate }}
    </button>
</div>
