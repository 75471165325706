import { Component, Inject } from '@angular/core';
import { } from '@angular/material/dialog';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TableComponent } from '../table/table.component';
import { TableColumn } from '@shared/table/data-access/table-column';

@Component({
    selector: 'app-table-settings',
    templateUrl: './table-settings.component.html',
    styleUrls: ['./table-settings.component.scss']
})
export class TableSettingsComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: TableComponent,
    ) {
        this.table = data;

        this.refreshData();
    }

    protected dataSource: MatTableDataSource<TableColumn> = new MatTableDataSource<TableColumn>();
    protected table!: TableComponent;

    protected readonly columnsToShow: string[] = ['index', 'drag', 'label', 'actions'];

    drop(event: CdkDragDrop<any[]>): void {
        const previousItem: TableColumn = this.dataSource.data[event.previousIndex];
        const currentItem: TableColumn = this.dataSource.data[event.currentIndex];

        this.table.columnLayout.setIndexOf(previousItem, currentItem);

        this.refreshData();
    }

    protected restoreDefaults(): void {
        this.table.restoreDefaultSettings();

        this.refreshData();
    }

    protected toggleColumn(column: TableColumn, event: MatCheckboxChange): void {
        column.settings.display = event.checked;
    }

    private refreshData(): void {
        this.dataSource.data = this.table.columnLayout.sortedColumns
            .filter(column => !column.settings.hidden);
    }
}
