import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {
    @Input() decimalPlaces: number | null = null;
    @Input() integerOnly: boolean = false;
    @Input() positiveOnly: boolean = false;

    private navigationKeys = [
        'Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste'
    ];

    constructor(public el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        if (this.navigationKeys.indexOf(e.key) > -1 || (e.key === 'a' && e.ctrlKey === true) || (e.key === 'c' && e.ctrlKey === true)) {
            return;
        }

        if (this.navigationKeys.indexOf(e.key) > -1 ||
            (e.key === 'a' && e.ctrlKey === true) ||
            (e.key === 'c' && e.ctrlKey === true) ||
            (e.key === 'v' && e.ctrlKey === true)) {
            return;
        }

        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(e.key);

        if (!this.isValid(next)) {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(e: ClipboardEvent) {
        const clipboardData = e.clipboardData || window['clipboardData'];

        const pastedText = clipboardData.getData('text')
            .replace(/\s+/g, '');

        const current: string = this.el.nativeElement.value;
        const start = this.el.nativeElement.selectionStart;
        const end = this.el.nativeElement.selectionEnd;

        const next = current.slice(0, start) + pastedText + current.slice(end);

        e.preventDefault();

        if (this.isValid(next)) {
            this.el.nativeElement.value = next;
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }
    }

    private isValid(next: string): boolean {
        let sign = this.positiveOnly ? '' : '-?';
        let decimal = this.integerOnly ? '' : '[\\.,]?\\d{0,' + (this.decimalPlaces || '') + '}';
        let regex = new RegExp(`^${sign}\\d*${decimal}$`, 'g');

        return !next || !!String(next).match(regex);
    }
}
