import { Directive, Input } from "@angular/core";

@Directive({
    selector: '[appTableColumn]'
})
export class TableColumnDirective {
    @Input('appTableColumn') label: any;
    @Input() settingHidden: boolean = false;
    @Input() settingLocked: boolean = false;
    @Input() settingDefault: boolean = true;
}
