import { Injectable, Injector } from "@angular/core";
import { DefaultErrorHandler } from "./error-handlers/default-error-handler.service";
import { ErrorHandler } from "./error-handler";
import { APP_CON_01_ErrorHandler } from "./error-handlers/app-con-01-error-handler.service";
import { DefaultErrorCodeHandler } from "./error-handlers/default-error-code-handler.service";

@Injectable({ providedIn: 'root' })
export class ErrorHandlerFactory {
    constructor(private readonly injector: Injector) { }

    get defaultHandler(): ErrorHandler {
        return this.injector.get(DefaultErrorHandler);
    }

    /**
     * Create an error handler.
     *
     * @param error Error to handle.
     *
     * @returns An error handler for handling {@link error}.
     */
    createHandler(error: any): ErrorHandler {
        const errorCode: string | null = error?.error?.code ?? error?.code;

        if (errorCode == 'APP-CON_01')
            return this.injector.get(APP_CON_01_ErrorHandler);

        if (errorCode != null && errorCode != 'UNKNOWN')
            return this.injector.get(DefaultErrorCodeHandler);

        return this.defaultHandler;
    }
}
