import { Injectable, NgZone } from "@angular/core";
import { ErrorHandler } from "../error-handler";
import { AppService } from "@shared/data-access/app/app.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "@shared/data-access/auth/auth.service";
import { Observable, of } from "rxjs";

/**
 * Default error handler.
 *
 * TODO: this error handler can be split into many different ones.
 * Ultimately this should be the simplest handler.
 */
@Injectable({ providedIn: 'root' })
export class DefaultErrorHandler implements ErrorHandler {
    constructor(
        private readonly app: AppService,
        private readonly auth: AuthService,
        private readonly router: Router,
        private readonly ngZone: NgZone
    ) { }

    handle(error: any): Observable<void> {
        let showText: boolean = true;

        let text = this.getHttpError(error);

        if (error instanceof HttpErrorResponse) {
            if (error.url.indexOf(this.auth.refreshUrl) !== -1) {
                this.router.navigate(['/main']);
            } else {
                if (error.status === 401) {
                    if (!this.auth.isAuthenticated) {
                        showText = false;
                        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: '/' + this.router.url } });
                    } else {
                        text = this.app.translate('error.accessDenied');
                    }
                } /* else if (error.status === 403) {
          text = this.app.translate('error.accessDenied');
        } else if (error.status === 404) {
          text = this.app.translate('error.notFound');
        }*/
            }
        } else if (!text) {
            text = this.app.translate(error.message || error);
        }

        this.ngZone.run(() => {
            this.app.hideLoading();

            this.app.alerts.error(this.app.translate('error.unknown'));
        });

        return of();
    }

    private getHttpError(error: any): string {
        if (!error) {
            return this.app.translate('error.unknown');
        }

        let text = '';
        let details: string[] = [];

        const requiredFieldErr = (text: string) => {
            const reqFieldErr = text.split('Required');

            if (reqFieldErr.length === 2) {
                const reqField = this.app.translate(reqFieldErr[0]);
                return this.app.translate('error.fieldRequired').replace('{0}', reqField);
            }

            return '';
        };

        if (error instanceof HttpErrorResponse) {
            if (error.error) {
                const httpErr = error.error;

                if (typeof httpErr === 'string') {
                    text = httpErr;
                } else {
                    text = httpErr.message || httpErr.title;
                    details = httpErr.details;
                }
            }

            if (text) {
                text = this.app.translate(text);

                if (!text || text === error.message) {
                    text = requiredFieldErr(text);
                }
            } else if (error.status) {
                let statusKey: string;

                switch (error.status) {
                    case 403:
                        statusKey = 'accessDenied';
                        break;
                    case 404:
                        statusKey = 'notFound';
                        break;
                    default:
                        statusKey = error.status.toString();
                }

                const errorCodeKey = `error.${statusKey}`;
                const httpErrorText = this.app.translate(errorCodeKey);

                if (httpErrorText && httpErrorText !== errorCodeKey) {
                    text = httpErrorText;
                }
            }
        }

        if (!text) {
            text = this.app.translate(error.message);

            if (!text || text === error.message) {
                text = this.app.translate('error.unknown');
            }
        }

        if (details && details.length) {
            const unique = details.reduce((curr, next) => {
                if (curr.includes(next)) {
                    curr.push(next);
                }

                return curr;
            }, []);

            unique.forEach((t) => {
                if (t && typeof t === 'string') {
                    text += `<br>${requiredFieldErr(t) || this.app.translate(t)}`;
                }
            });
        }

        return text;
    }
}
