<h1 mat-dialog-title>
    {{ (!!id ? "person.editTitle" : "person.createTitle") | translate }}
</h1>

<div mat-dialog-content>
    <form [formGroup]="form">
        <div class="row">
            <div class="col">
                <app-text-edit-field [isEdit]="isEdit" formControlName="firstName"
                    [label]="'person.firstName'"></app-text-edit-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <app-text-edit-field [isEdit]="isEdit" formControlName="lastName"
                    [label]="'person.lastName'"></app-text-edit-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <app-text-edit-field [isEdit]="isEdit" formControlName="email"
                    [label]="'person.email'"></app-text-edit-field>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!form.valid || !form.dirty || saving">
        {{ "btn.save" | translate }}
        <app-spinner *ngIf="saving"></app-spinner>
    </button>
    <button mat-stroked-button mat-dialog-close [disabled]="saving">
        {{ "btn.cancel" | translate }}
    </button>
</div>
