import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

import { AlertService } from '@shared/data-access/app/alert.service';
import { AuthService } from '@shared/data-access/auth/auth.service';

import { FontSize } from '@core/font-size';
import { IBreadcrumb } from '@core/breadcrumb';
import { User } from '@shared/data-access/auth/models/user';

import { ILocalizedString, Locale } from '@zj/paka-client';

/**
 * Common application service
 */
@Injectable({ providedIn: 'root' })
export class AppService {
    constructor(
        public alerts: AlertService,
        public translator: TranslateService,
        private auth: AuthService
    ) { }

    readonly defaultLanguage: string = 'lv';

    get isAuthenticated(): boolean {
        return this.auth.isAuthenticated;
    }

    get currentUser(): User {
        return this.auth.currentUser;
    }

    get currentLanguage(): string {
        return this.translator.currentLang;
    }

    get currentRoute(): string {
        return this.routeHistory[1];
    }

    get previousRoute(): string {
        return this.routeHistory[0];
    }

    private loadings: { id: number; active: boolean }[] = [];

    private fontSizeSubject = new BehaviorSubject<FontSize>(<FontSize>localStorage.getItem('fontSize'));
    private documentClickSubject = new Subject<any>();
    private afterLoginSubject = new Subject<void>();
    private titleSubject = new Subject<string>();
    private breadcrumbSubject = new Subject<IBreadcrumb[]>();

    private routeHistory: string[] = [undefined, undefined];

    /**
     * Show loading animation.
     * @param delay
     * @returns Id of the loading animation
     */
    showLoading(delay: number = 100): number {
        const data = {
            id: Date.now(),
            active: undefined
        };

        this.loadings.push(data);

        setTimeout(() => {
            if (data.active !== false) {
                data.active = true;
            }
        }, delay);

        return data.id;
    }

    /**
     * Hide loading animation.
     * @param id Id of the loading animation. If omitted, then hide all loading animations.
     * @param delay
     */
    hideLoading(id?: number, delay: number = 400) {
        setTimeout(() => {
            if (id) {
                const i = this.loadings.findIndex((t) => t.id === id);

                if (i > -1) {
                    this.loadings[i].active = false;
                    this.loadings.splice(i, 1);
                }
            } else {
                this.loadings.forEach((t) => this.hideLoading(t.id, delay));
            }
        }, delay);
    }

    /**
     * Remove all loading animations.
     */
    clearLoading() {
        this.loadings = [];
    }

    /**
     * Check if there is an active loading animation.
     */
    isLoading(): boolean {
        return this.loadings.some((t) => t.active);
    }

    /**
     * Get instant translation by key.
     * @param key
     */
    translate(key: string): string {
        if (!key) {
            return '';
        }

        let value = this.translator.instant(key);

        if (value instanceof Array) {
            value = value.join('');
        }

        return value;
    }

    translateProperty(item: any, property: string): any {
        if (!item) return '';

        const langProp = `${property}${this.currentLanguage}`.toLowerCase();
        let result = item[property];

        Object.keys(item).forEach((t) => {
            if (t.toLowerCase() === langProp) {
                result = item[t];
            }
        });

        return result;
    }

    loggedIn() {
        this.afterLoginSubject.next();
    }

    afterLogin(): Observable<void> {
        return this.afterLoginSubject.asObservable();
    }

    setFontSize(size: FontSize) {
        localStorage.setItem('fontSize', size);
        this.fontSizeSubject.next(size);
    }

    getFontSize(): Observable<FontSize> {
        return this.fontSizeSubject.asObservable();
    }

    /**
     * Set current page title.
     * @param title
     */
    setTitle(title: string) {
        this.titleSubject.next(title);
    }

    /**
     * Get current page title.
     */
    getTitle(): Observable<string> {
        return this.titleSubject.asObservable();
    }

    documentClick(event) {
        this.documentClickSubject.next(event);
    }

    onDocumentClick() {
        return this.documentClickSubject.asObservable();
    }

    addRouteHistory(route: string) {
        if (route == this.currentRoute) return;

        this.routeHistory[0] = this.routeHistory[1];
        this.routeHistory[1] = route;
    }

    /**
     * Get user selected language. If not set, default language  is returned.
     */
    getUserLanguage(): string {
        return localStorage.getItem('language') || this.defaultLanguage;
    }

    switchLanguage(language: string) {
        if (language) {
            localStorage.setItem('language', language);
            location.reload();
        }
    }

    checkFormValidity(form: UntypedFormGroup): boolean {
        if (!form.valid) {
            this.alerts.error(this.translate('error.requiredFields'));
            return false;
        }

        return true;
    }

    confirmDataLoss() {
        return this.alerts.confirm({
            text: this.translate('common.confirmUnsavedDataLoss'),
            ok: this.translate('common.yes')
        });
    }

    getBreadcrumbs(): Observable<IBreadcrumb[]> {
        return this.breadcrumbSubject.asObservable();
    }

    setBreadcrumbs(items: IBreadcrumb[]) {
        this.breadcrumbSubject.next(items);
    }

    pickLocalizedString(value: ILocalizedString[]): string {
        let result: string;

        switch (this.currentLanguage) {
            case Locale.EN.toLowerCase():
                result = value.find(t => t.locale == Locale.EN)?.value;
                break;
            case Locale.LV.toLowerCase():
            default:
                result = value.find(t => t.locale == Locale.LV)?.value;
                break;
        }

        if (!result) {
            let defaultTranslation: ILocalizedString | null = value.find(t => t.locale == Locale.LV);

            result = !!defaultTranslation ? `${defaultTranslation.value} (${defaultTranslation.locale})` : null;
        }

        // If result is still empty, then try to pick the first
        // found value.
        if (!result) {
            const anyValue: ILocalizedString | null = value.find(t => !!t.value);

            result = !!anyValue ? `${anyValue.value} (${anyValue.locale})` : null;
        }

        return result;
    }
}
