<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <input *ngIf="!isTextarea" matInput [ngModel]="value" (ngModelChange)="setValue($event)" [disabled]="isDisabled"
        [required]="isRequired" [maxLength]="maxlength" (blur)="onBlur()" [errorStateMatcher]="errorStateMatcher" />

    <textarea *ngIf="isTextarea" matInput [ngModel]="value" (ngModelChange)="setValue($event)" [disabled]="isDisabled"
        [required]="isRequired" [maxLength]="maxlength" (blur)="onBlur()" [errorStateMatcher]="errorStateMatcher"
        cdkTextareaAutosize>
    </textarea>

    <span matSuffix>
        <ng-content select="[suffix]"></ng-content>
    </span>

    <button *ngIf="clearEnabled && value" mat-icon-button matSuffix (click)="setValue(null)" type="button">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
</mat-form-field>
