export class Group<T extends object> {
    constructor(
        key: any,
        item: T,
        level: number,
        open?: boolean
    ) {
        this._key = key;
        this.items = [item];
        this.level = level;
        this.open = open ?? false;
    }

    // Must not conflict with a property in T.
    _key: any;

    items: (Group<T> | T)[];
    level: number;
    open: boolean;

    /**
     * All T items from this group or all subgroups.
     */
    get subGroupItems(): T[] {
        let result: T[] = [];

        Group.findSubgroupItems(result, this);

        return result;
    }

    /**
     * Recusively find all items/tree leaves.
     * @param result Filled with items/tree leaves.
     * @param root Root group/node.
     */
    private static findSubgroupItems<T extends object>(result: T[], root: Group<T>): void {
        if (root.items.some(t => '_key' in t))
            root.items.forEach(item => Group.findSubgroupItems(result, item as Group<T>))
        else {
            result.push(...(root.items as T[]));
        }
    }
}
