import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appFormatDecimal'
})
export class FormatDecimalPipe implements PipeTransform {

    transform(value: number | null, decimalPlaces: number = 3): string {
        if (value == null || value == undefined)
            return null;

        return value.toFixed(decimalPlaces);
    }
}
