<div>
    <input name="file" type="file" #fileInput (change)="onFileSelect($event)" [accept]="acceptExtensions"
        [multiple]="true" style="display: none" />

    <button mat-button color="primary" type="button" (click)="fileInput.click()">
        <mat-icon>attach_file</mat-icon>
        {{ 'btn.addAttachments' | translate }}
    </button>

    <ul class="attachment-list">
        <li *ngFor="let file of value">
            {{ file.name }}
            <button mat-icon-button type="button" (click)="removeFile(file)">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </li>
    </ul>
</div>
