import { Token } from "./token";

export class TemplateParser {
    /**
     * Parse a template text.
     *
     * Extracts tokens ({@link Token}) and replaces them with values from {@link context}.
     *
     * @param template Template text to parse.
     * @param context Context values to replace tokens with.
     *
     * @returns Parsed template text.
     */
    static parse(template: string, context: any): string {
        const tokens: Token[] = Token.extractTokens(template);

        let result: string = template;

        for (let i = 0; i < tokens.length; i++) {
            const token: Token = tokens[i];
            const contextValue: any = context[token.id];

            result = result.replace(token.asString(), contextValue);
        }

        return result;
    }
}
