<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <app-file-input [ngModel]="value" (ngModelChange)="setValue($event)" [required]="isRequired" [disabled]="isDisabled"
        [acceptExtensions]="acceptExtensions" (blur)="onBlur()" [hasErrors]="errors != null">
    </app-file-input>

    <span matSuffix class="multi-suffix">
        <button *ngIf="downloadEnabled && !value?.type && value?.name" mat-icon-button (click)="download.emit()" type="button">
            <mat-icon class="fiv-sqo fiv-icon-blank fiv-icon-{{(value.name || '.blank').split('.').pop()}}">
            </mat-icon>
        </button>

        <button *ngIf="clearEnabled && value?.name" mat-icon-button (click)="setValue(null)" type="button">
            <mat-icon>clear</mat-icon>
        </button>
    </span>

    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>

    <mat-error *ngIf="errors?.fileMaxSize && value">
        {{ "file.maxSizeError" | translate | formatString : [prettySize(value.size),
        prettySize(errors.fileMaxSize.value)] }}
    </mat-error>
    <mat-error *ngIf="errors?.fileExtension">
        {{ "file.extensionError" | translate | formatString : errors.fileExtension.value }}
    </mat-error>
</mat-form-field>
