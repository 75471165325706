import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { NumberFieldValueManager } from './number-field-value-manager';
import { CustomErrorStateMatcher } from '@core/custom-error-state-matcher';

@Component({
    selector: 'app-number-field',
    templateUrl: './number-field.component.html'
})
export class NumberFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() clearEnabled: boolean = false;
    @Input() decimalPlaces: number | null = null;
    @Input() inline: boolean = false;
    @Input() integerOnly: boolean = false;
    @Input() label: string;
    @Input() positiveOnly: boolean = false;
    @Input() required: boolean = false;

    isDisabled: boolean;
    isRequired: boolean;

    protected valueManager: NumberFieldValueManager = new NumberFieldValueManager();

    readonly errorStateMatcher = new CustomErrorStateMatcher(this.parent);

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: string | null): void {
        this.valueManager.displayValue = value;
        this.onChange(this.valueManager.value);
    }

    writeValue(value: number | null): void {
        this.valueManager.value = value;
    }

    registerOnChange(fn: (value: number | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    private onChange = (value: number | null) => { };
    private onTouch = () => { };
}
