import { Component, Input, Optional, Self } from '@angular/core';
import { AbstractControl, NgControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent } from '@angular/material/legacy-chips';
import { CustomErrorStateMatcher } from '@core/custom-error-state-matcher';
import { Util } from '@zj/paka-client';

@Component({
    selector: 'app-multi-value-text-field',
    templateUrl: './multi-value-text-field.component.html',
    styleUrls: ['./multi-value-text-field.component.scss']
})
export class MultiValueTextFieldComponent {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() clearEnabled: boolean = false;
    @Input() hint: string | null = null;
    @Input() inline: boolean = false;
    @Input() label: string;

    activeInput: string | null = null;
    passiveValues: string[] = [];
    isDisabled: boolean;
    isRequired: boolean;

    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    readonly errorStateMatcher = new CustomErrorStateMatcher(this.parent);

    get value(): string[] {
        let value: string[] = [...this.passiveValues];

        if (this.activeInput)
            value.push(this.activeInput);

        return value;
    }

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: string): void {
        this.activeInput = value;

        this.onChange(this.value);
    }

    writeValue(value: string[] | null): void {
        this.passiveValues = value ?? [];
    }

    registerOnChange(fn: (value: string[] | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    protected add(event: MatLegacyChipInputEvent): void {
        const value: string = (event.value || '').trim();

        if (value)
            this.passiveValues.push(value);

        this.activeInput = '';

        event.chipInput!.clear();
    }

    protected clear(): void {
        this.activeInput = null;
        this.passiveValues = [];

        this.onChange(this.value);
    }

    protected remove(value: string): void {
        Util.removeItemFromArray(this.passiveValues, value);

        this.onChange(this.value);
    }

    private onChange = (value: string[] | null) => { };
    private onTouch = () => { };
}
