import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { ServiceDate } from '@core/service-date';

@Component({
    selector: 'app-service-date-edit-field',
    templateUrl: './service-date-edit-field.component.html'
})
export class ServiceDateEditFieldComponent implements ControlValueAccessor {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() displayClass: string = 'valbox';
    @Input() inline: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() label: string;

    displayValue: string | null = null;

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    setValue(value: ServiceDate | null): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: ServiceDate | null): void {
        this.displayValue = value?.toString();
    }

    registerOnChange(fn: (value: ServiceDate | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private onChange = (value: ServiceDate | null) => { };
    private onTouch = () => { };
}
