import { OnDestroy, TemplateRef } from '@angular/core';
import { Directive } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { AppService } from '@shared/data-access/app/app.service';
import { Util } from '@zj/paka-client';

@Directive({
    selector: '[appFieldError]'
})
export class FieldErrorDirective implements OnDestroy {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private app: AppService
    ) { }

    private subscription: Subscription;
    private currentError: string;

    @Input() set appFieldError(control: AbstractControl) {
        this.subscription = control.statusChanges.subscribe(() => {
            const error = this.getError(control);

            if (error) {
                if (error != this.currentError) {
                    this.viewContainer.clear();
                    this.viewContainer.createEmbeddedView(this.templateRef, { '$implicit': error });
                }
            } else {
                this.viewContainer.clear();
            }

            this.currentError = error;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private getError(control: AbstractControl): string {
        if (control?.errors) {
            const errors = control.errors;

            for (const key in errors) {
                if (errors[key].message) {
                    return errors[key].message;
                } else {
                    switch (key) {
                        case 'minlength':
                            return Util.formatString(this.app.translate('fieldError.minLength'), [errors.minlength.requiredLength]);

                        case 'maxlength':
                            return Util.formatString(this.app.translate('fieldError.maxLength'), [errors.maxlength.requiredLength]);

                        case 'min':
                            return Util.formatString(this.app.translate('fieldError.min'), [errors.min.min]);

                        case 'max':
                            return Util.formatString(this.app.translate('fieldError.max'), [errors.max.max]);

                        case 'required':
                            return null;

                        default:
                            return errors[key];
                    }
                }
            }
        }

        return null;
    }
}
