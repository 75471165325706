<app-select-field *ngIf="isEdit" [label]="label" [inline]="inline" [hasEmptyOption]="hasEmptyOption"
    [searchEnabled]="true" [displayTemplate]="displayTemplate" [options]="options"
    (searchChange)="searchClients($event)" [formControl]="control" (ngModelChange)="setValue($event)">

    <!-- Redirect to client display form -->
    <span suffix *ngIf="value">
        <button mat-icon-button type="button" (click)="viewClient($event)">
            <mat-icon>link</mat-icon>
        </button>
    </span>

    <!-- Redirect to client creation form -->
    <span suffix *ngIf="!value && enableCreation">
        <button mat-icon-button type="button" (click)="createClient($event)">
            <mat-icon>add</mat-icon>
        </button>
    </span>
</app-select-field>

<app-value-field *ngIf="!isEdit" [displayClass]="displayClass" [displayTemplate]="displayTemplate" [inline]="inline"
    [label]="label" [value]="displayValue">
</app-value-field>

<ng-template #displayTemplate let-value="value">
    {{ value?.name }}
</ng-template>
