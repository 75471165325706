import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-accordion-item',
    templateUrl: './accordion-item.component.html',
    styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent {
    @Input() title: string;
    @Input() titleTemplate: TemplateRef<any>;

    @Input('expanded') set exp(expanded: boolean) {
        this.expanded = expanded;
    }

    @Output() closed: EventEmitter<void> = new EventEmitter<void>();
    @Output() opened: EventEmitter<void> = new EventEmitter<void>();

    protected expanded = false;

    protected toggle(): void {
        if (this.expanded)
            this.closed.emit();
        else
            this.opened.emit();

        this.expanded = !this.expanded;
    }
}
