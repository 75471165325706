
<app-group-table-filter *ngIf="showFilter" [groupManager]="groupManager"></app-group-table-filter>

<ng-content></ng-content>
<table mat-table [dataSource]="tableData">

    <ng-container matColumnDef="groupHeader">
        <td *matCellDef="let group" mat-cell colspan="999" class="cell">
            <span style="display:inline-flex;align-items:center;padding-left:{{ group.level * 20 }}px">
                <button mat-icon-button type="button" (click)="toggleOpen(group)">
                    <mat-icon *ngIf="!group.open">chevron_right</mat-icon>
                    <mat-icon *ngIf="group.open">expand_more</mat-icon>
                </button>
                {{ groupManager.groupByClauses[group.level].labelPredicate(group._key, group.subGroupItems) }}
            </span>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="group-row"> </tr>
</table>
