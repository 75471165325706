import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { ITreeNode } from '@zj/paka-client';

@Component({
    selector: 'app-tree',
    template: `<ng-container [ngTemplateOutlet]="template || templateRef" [ngTemplateOutletContext]="{$implicit: node}"></ng-container>`
})
export class TreeComponent<T> {
    @Input() node: ITreeNode<T>;
    @Input() template: TemplateRef<any>;
    @ContentChild(TemplateRef, { static: true })
    templateRef: TemplateRef<any>;
}
