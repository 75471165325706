import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '@zj/paka-client';

@Pipe({
    name: 'formatString'
})
export class FormatStringPipe implements PipeTransform {
    transform(value: string, replacements: any): any {
        return Util.formatString(value, replacements);
    }
}
