import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

export class CustomMatPaginator {
  constructor(private readonly translate: TranslateService) { }

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPage');
    paginatorIntl.nextPageLabel = this.translate.instant('paginator.nextPage');
    paginatorIntl.previousPageLabel = this.translate.instant('paginator.prevPage');
    paginatorIntl.firstPageLabel = this.translate.instant('paginator.firstPage');
    paginatorIntl.lastPageLabel = this.translate.instant('paginator.lastPage');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);

    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('paginator.range', { startIndex: 0, endIndex: 0, length });
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // if the start index exceeds the list length, do not try and fix the end index to the end
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return this.translate.instant('paginator.range', { startIndex: startIndex + 1, endIndex, length });
  }
}
