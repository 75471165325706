export class NumberFieldValueManager {
    public displayValue: string | null = null;

    get value(): number | null {
        if(!this.displayValue)
            return null;

        let result = Number(this.displayValue.replace(',', '.'));

        if(isNaN(result))
            return null;

        return result;
    }
    set value(value: number | null) {
        this.displayValue = value?.toString() ?? null;
    }
}
