import { Component, Input } from '@angular/core';
import { INavLink } from '../../../data-access/models/nav-link.model';

@Component({
    selector: 'app-sub-header-link',
    templateUrl: './sub-header-link.component.html',
    styleUrls: ['./../sub-header.component.scss', '../../header.component.scss']
})
export class SubHeaderLinkComponent {
    @Input() link: INavLink;
    @Input() colorName: string;
}
