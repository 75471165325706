import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { AlertOptions, AlertType } from '@shared/data-access/app/alert.service';

@Component({
    selector: 'app-alert',
    template: `
    <div class="alert-dialog alert-dialog-{{data.type.toLowerCase()}}">
        <h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
        <h2 mat-dialog-title *ngIf="isError && !data.title">{{'common.errorTitle' | translate}}</h2>
        <mat-dialog-content>
            <div [innerHTML]="data.text | safeHtml"></div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-stroked-button mat-dialog-close>{{'btn.close' | translate}}</button>
        </mat-dialog-actions>
    </div>`,
    styles: ['.mat-dialog-actions { justify-content: center }']
})
export class AlertComponent {
    constructor(
        public dialogRef: MatDialogRef<AlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AlertOptions
    ) {
        this.isError = this.data.type == AlertType.Error;
    }

    public isError: boolean;
}
