import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, Validators } from '@angular/forms';

@Component({
    selector: 'app-rich-text-field',
    templateUrl: './rich-text-field.component.html',
    styleUrls: ['./rich-text-field.component.scss']
})
export class RichTextFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() label: string;

    value: string;
    isDisabled: boolean;
    isRequired: boolean;

    get hasErrors(): boolean {
        return this.parent?.control?.touched && this.errors !== null;
    }

    get errors(): ValidationErrors | null {
        return this.parent?.control?.errors || null;
    }

    readonly modules = {
        toolbar: [
            ['bold', 'italic'],
            ['blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'header': [2, 3, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
            ['clean'],
            ['image', 'link']
        ]
    }

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: any): void {
        this.value = value.html;
        this.onChange(this.value);
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    private onChange = (value: string) => { };
    private onTouch = () => { };

}
