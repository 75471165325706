import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-text-more-less',
    template: `
  {{isFullText || safeText.length - minHidden <= maxLength ? safeText : safeText.substring(0, maxLength) + '...'}}
  <a href="#" *ngIf="safeText.length - minHidden > maxLength" (click)="$event.preventDefault(); isFullText = !isFullText" class="text-more-less">
    <span *ngIf="!isFullText">{{'btn.showMore' | translate}} &raquo;</span>
    <span *ngIf="isFullText">&laquo; {{'btn.showLess' | translate}}</span>
  </a>
    `
})
export class TextMoreLessComponent {
    @Input() text: string;
    @Input() maxLength: number = 300;
    /**
     * Minimum amount of text to be hidden. If full text length and max length difference is less than minHidden, then full text is shown.
     */
    @Input() minHidden: number = 50;

    isFullText: boolean;

    get safeText(): string {
        return this.text || '';
    }
}
