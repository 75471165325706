import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router, RouterEvent } from '@angular/router';
import { AppService } from '@shared/data-access/app/app.service';
import { AuthService } from '@shared/data-access/auth/auth.service';
import { appVersion } from '@version';
import { FontSize } from '@core/font-size';
import { Menu } from '../data-access/models/menu';
import { NavService } from '../data-access/nav.service';
import { User } from '@shared/data-access/auth/models/user';
import { INavLink } from '../data-access/models/nav-link.model';
import { ParameterClient, ParameterCode } from '@zj/paka-client/parameters';
import { supportedLocales } from '@zj/paka-client';

interface ILanguage {
    code: string;
    label: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private parameters: ParameterClient,
        private router: Router,
        private nav: NavService
    ) {
        this.languages = supportedLocales.map(t => ({ code: t.toLowerCase(), label: t }));

        this.languageMenu = new Menu('language',
            this.languages.filter(t => t.code !== this.app.currentLanguage).map(t => <INavLink>{ name: t.label, value: t.code }),
            this.app.currentLanguage.toUpperCase()
        );

        this.menus.push(this.languageMenu);
    }

    readonly appVersion = appVersion;

    protected appTitle: string;
    protected userName: string;
    protected userDisplayName: string;
    protected isAuthenticated: boolean;
    protected isAdmin: boolean;
    protected mobileNavOpened: boolean;

    protected languageMenu: Menu;

    readonly fontSizes: FontSize[] = [FontSize.Small, FontSize.Normal, FontSize.Large];
    protected readonly languages: ILanguage[];

    readonly adminMenu = this.nav.getAdminMenu();
    readonly userMenu = this.nav.getUserMenu();

    protected menus: Menu[] = [this.adminMenu, this.userMenu];
    protected subHeaderMenus: Menu[] = [...this.nav.getCategoryMenus(), this.nav.getClientMenu(), this.nav.getEInvoiceMenu()];

    protected fontSize = FontSize.Normal;
    protected language: string;

    ngOnInit() {
        this.router.events.subscribe((e) => {
            this.interceptNavigation(e as RouterEvent);
        });

        this.app.onDocumentClick().subscribe((e) => {
            if (this.mobileNavOpened) return;

            const opened = [
                ...this.menus.filter((t) => t.isOpened),
                ...this.subHeaderMenus.map((t) => t.getMenus().filter((t) => t.isOpened))
                    .flat()
            ];

            if (opened.length) {
                if (!e.target.closest('.sub-nav-toggle') && !e.target.closest('.sub-nav')) {
                    opened.forEach((t) => t.close());
                }
            }
        });

        const loading = this.app.showLoading();
        this.parameters.get().subscribe(data => {
            this.app.hideLoading(loading);
            this.appTitle = this.parameters.findValue(ParameterCode.AppTitle, data);
        });

        this.auth.getUser().subscribe((user: User) => {
            this.isAuthenticated = user.isAuthenticated;
            this.isAdmin = user.isAdmin;
            this.userName = user.userName;
            this.userDisplayName = user.displayName;
        });

        this.app.getFontSize().subscribe((size) => {
            if (size) this.fontSize = size;
        });
    }

    toggleMenu(menu: Menu): void {
        this.menus.filter((t) => t !== menu).forEach((t) => t.close());
        this.subHeaderMenus.forEach((t) =>
            t.getMenus().filter((t) => t !== menu).forEach((t) => t.close()));

        menu.toggle();
    }

    openSubHeaderMenu(menu: Menu, navigate: boolean = true): void {
        this.subHeaderMenus.filter((t) => t !== menu).forEach((t) => t.close());
        menu.open();

        if (navigate) {
            const item = menu.items[0];

            if (!(item instanceof Menu)) {
                // Item is INavLink
                let navigationExtras: NavigationExtras = <NavigationExtras>{};

                if (item.queryParams)
                    navigationExtras.queryParams = item.queryParams;

                this.router.navigate([item.url], navigationExtras);
            };
        }
    }

    switchLanguage(code: string): void {
        this.app.switchLanguage(code);
    }

    changeFontSize(size: FontSize): void {
        this.app.setFontSize(size);
    }

    getVisible(items: INavLink[]): INavLink[] {
        return items && items.filter((t) => !t.visible || t.visible(t));
    }

    private interceptNavigation(event: RouterEvent) {
        this.mobileNavOpened = false;
        this.menus.forEach((t) => t.close());
        this.subHeaderMenus.forEach((t) => t.getMenus().forEach((t) => t.close()));

        if (event instanceof NavigationEnd) {
            const url = event.url.split('/').slice(1, 3).join('/').split('?')[0];

            const activeUrlMenu = this.subHeaderMenus.find(t => t.isActiveByUrl(url));
            const openedMenu = this.subHeaderMenus.find(t => t.isOpened);

            if (activeUrlMenu === openedMenu)
                return;

            if (activeUrlMenu && !openedMenu)
                this.openSubHeaderMenu(activeUrlMenu, false);
            else
                this.subHeaderMenus.forEach((t) => t.close());
        }
    }
}
