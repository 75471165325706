import { Component, Input } from '@angular/core';
import { GroupManager } from '../group-manager';

@Component({
    selector: 'app-group-table-filter',
    templateUrl: './group-table-filter.component.html',
    styleUrls: ['./group-table-filter.component.scss']
})
export class GroupTableFilterComponent<T extends object> {
    @Input() groupManager: GroupManager<T>;

    protected values: any[] = [];

    protected filter(level: number, value: any): void {
        if (!event)
            return;

        this.groupManager.filter(level, value);
    }

    protected getFilterOptions(index: number): any[] {
        let set = this.groupManager.levelKeyMap.get(index);

        if (set)
            return [...this.groupManager.levelKeyMap.get(index)];

        return [];
    }
}
