import { Component, EventEmitter, Input, Optional, Output, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
    selector: 'app-select-edit-field',
    templateUrl: './select-edit-field.component.html'
})
export class SelectEditFieldComponent implements ControlValueAccessor {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() clearEnabled: boolean = false;
    @Input() displayClass: string = 'valbox';
    @Input() hasEmptyOption: boolean = true;
    @Input() inline: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() label: string;
    @Input() searchEnabled: boolean = false;

    @Input() multiple: boolean = false;
    @Input() separator: string = ',';

    @Input() displayTemplate: TemplateRef<any>;
    @Input() optionHeaderTemplate: TemplateRef<any>;
    @Input() optionTemplate: TemplateRef<any>;
    @Input() triggerTemplate: TemplateRef<any>;

    @Input() set options(value: any) {
        this._options = value;
        this.writeValue(this.value);
    }

    @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

    value: any | any[];
    displayValue: any | any[];

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    get options(): any[] {
        return this._options;
    }

    private _options: any[] = [];

    setValue(value: any): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: any): void {
        this.value = value;
        this.displayValue = Array.isArray(value)
            ? this.options.filter(t => this.displayValue.includes(this.extractValue(t)))
            : this.options.find(t => this.extractValue(t) === value) || value
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private extractValue(value: any): any {
        return value?.id ?? value;
    }

    private onChange = (value: any) => { };
    private onTouch = () => { };
}
