<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <mat-select disableOptionCentering [ngModel]="value" (ngModelChange)="setValue($event)" [compareWith]="compare"
        [disabled]="isDisabled" [required]="isRequired" (blur)="onBlur()" [multiple]="multiple"
        [errorStateMatcher]="errorStateMatcher">

        <!-- Trigger -->
        <mat-select-trigger>
            <ng-container *ngIf="!multiple"
                [ngTemplateOutlet]="triggerTemplate ? triggerTemplate : (displayTemplate ? displayTemplate : defaultTemplate)"
                [ngTemplateOutletContext]="{option: displayValue, value: displayValue}">
            </ng-container>

            <ng-container *ngIf="multiple">
                <ng-container *ngFor="let n of displayValue">
                    <ng-container
                        [ngTemplateOutlet]="triggerTemplate ? triggerTemplate : (displayTemplate ? displayTemplate : defaultTemplate)"
                        [ngTemplateOutletContext]="{option: n, value: n}">
                    </ng-container>

                    <span class="separator">{{separator}} </span>
                </ng-container>
            </ng-container>
        </mat-select-trigger>

        <!-- Search -->
        <mat-option *ngIf="searchEnabled">
            <ngx-mat-select-search [placeholderLabel]="''" [clearSearchInput]="false" [searching]="searching"
                [formControl]="searchControl" [noEntriesFoundLabel]="''">
            </ngx-mat-select-search>
        </mat-option>

        <!-- Header -->
        <mat-option *ngIf="optionHeaderTemplate && options.length > 0" disabled>
            <ng-container [ngTemplateOutlet]="optionHeaderTemplate"></ng-container>
        </mat-option>

        <!-- Special options -->
        <!-- Empty option -->
        <mat-option *ngIf="hasEmptyOption" [value]="null"><i>{{ 'common.null' | translate }}</i></mat-option>
        <!-- Active option -->
        <mat-option *ngIf="searchEnabled && activeOption" [value]="activeOption">
            <i>
                <ng-container
                    [ngTemplateOutlet]="optionTemplate ? optionTemplate : (displayTemplate ? displayTemplate :  defaultTemplate)"
                    [ngTemplateOutletContext]="{option: activeOption, value: activeOption}">
                </ng-container>
            </i>
        </mat-option>

        <hr *ngIf="options.length > 1 && (hasEmptyOption || (searchEnabled && activeOption))" />

        <!-- Options list -->
        <ng-container *ngFor="let n of options">
            <mat-option *ngIf="!searchEnabled || !isActiveOption(n)" [value]="n">
                <ng-container
                    [ngTemplateOutlet]="optionTemplate ? optionTemplate : (displayTemplate ? displayTemplate :  defaultTemplate)"
                    [ngTemplateOutletContext]="{option: n, value: n}">
                </ng-container>
            </mat-option>
        </ng-container>
    </mat-select>

    <span matSuffix>
        <ng-content select="[suffix]"></ng-content>
    </span>

    <button *ngIf="clearEnabled && (multiple ? value?.length > 0 : value) " mat-icon-button matSuffix type="button"
        (click)="onClear($event)">
        <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>

<ng-template #defaultTemplate let-value="value">{{ value | translateProp }}</ng-template>
