import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorHandlerFactory } from '@shared/errors/error-handler-factory.service';
import { ErrorHandler as SpecificErrorHandler } from '@shared/errors/error-handler';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    constructor(
        private readonly injector: Injector
    ) { }

    private _errorHandlerFactory: ErrorHandlerFactory;
    private get errorHandlerFactory(): ErrorHandlerFactory {
        if (!this._errorHandlerFactory) this._errorHandlerFactory = this.injector.get(ErrorHandlerFactory);

        return this._errorHandlerFactory;
    }

    handleError(error: any): void {
        console.error(error);

        const errorHandler: SpecificErrorHandler = this.errorHandlerFactory.createHandler(error);

        errorHandler.handle(error)
            .subscribe({
                error: handlerError => this.errorHandlerFactory.defaultHandler.handle(error)
            });
    }
}
