import { Component, ContentChildren, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    MatLegacyTable as MatTable, MatLegacyColumnDef as MatColumnDef,
    MatLegacyTableDataSource as MatTableDataSource,
    MatLegacyHeaderRowDef as MatHeaderRowDef,
    MatLegacyRowDef as MatRowDef,
    MatLegacyCellDef as MatCellDef

} from '@angular/material/legacy-table';
import { Group } from './group';
import { IGroupByClause } from './group-clause.model';
import { GroupManager, GroupManagerOptions } from './group-manager';
import { Subscription, debounceTime } from 'rxjs';

@Component({
    selector: 'app-group-table',
    templateUrl: './group-table.component.html',
    styleUrls: ['./group-table.component.scss']
})
export class GroupTableComponent<T extends object> implements OnInit, OnDestroy {
    @Input() set items(items: T[]) {
        this.groupManager.items = items;
    };
    @Input() set defaultOpen(value: boolean) {
        this.groupManager = new GroupManager<T>(new GroupManagerOptions(value));
    }
    @Input() set groupByClauses(groupByClauses: IGroupByClause<T>[]) {
        this.groupManager.groupByClauses = groupByClauses;
    }
    @Input() showFilter: boolean = true;

    @ViewChild(MatTable, { static: true }) table: MatTable<T>;

    @ContentChildren(MatColumnDef) columnDefs;
    @ContentChildren(MatHeaderRowDef) headerRowDefs;
    @ContentChildren(MatRowDef) matRowDefs;
    @ContentChildren(MatCellDef) matCellDefs;

    protected groupManager: GroupManager<T> = new GroupManager<T>(new GroupManagerOptions(false));
    protected tableData: MatTableDataSource<(Group<T> | T)> = new MatTableDataSource<(Group<T> | T)>();

    private readonly subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.groupManager.statesChanges
            //.pipe(debounceTime(500))
            .subscribe(() => this.refreshTableData());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    ngAfterContentInit(): void {
        this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
        this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
        this.matRowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    }

    getItemPaddingLeft(item: T): string {
        const level: number = this.groupManager.itemLevelMap.get(item);

        let result = `${36 + level * 20}px`;

        return result;
    }

    protected isGroup(index: number, item: (Group<T> | T)): boolean {
        return '_key' in item;
    }

    protected toggleOpen(group: Group<T>): void {
        group.open = !group.open;

        this.refreshTableData();
    }

    private gatherTableItems(tableItems: (Group<T> | T)[], item: Group<T> | T): void {
        if ('_key' in item) {
            tableItems.push(item);

            if (item.open)
                item.items.forEach(t => this.gatherTableItems(tableItems, t));
        }
        else
            tableItems.push(item)
    }

    private refreshTableData(): void {
        let tableItems: (Group<T> | T)[] = [];

        this.groupManager.groups.forEach(item => {
            this.gatherTableItems(tableItems, item);
        });

        this.tableData = new MatTableDataSource<(Group<T> | T)>(tableItems);
    }
}
