import { Injectable } from '@angular/core';
import { Menu } from './models/menu';
import { AuthService } from '@shared/data-access/auth/auth.service';
import { AppService } from '@shared/data-access/app/app.service';
import { User } from '@shared/data-access/auth/models/user';
import { INavLink } from './models/nav-link.model';
import { Category } from '@zj/paka-client';
import { AuthType, UserType } from '@zj/paka-client/users';

/**
 * Nav service
 */
@Injectable({ providedIn: 'root' })
export class NavService {
    constructor(
        private app: AppService,
        private auth: AuthService
    ) { }

    private get user(): User {
        return this.auth.currentUser;
    }

    private readonly categoryLinks: INavLink[] = [
        {
            name: 'contracts',
            url: 'contracts'
        },
        {
            name: 'aaoContracts',
            url: 'aao-contracts'
        },
        {
            name: 'reports',
            url: 'reports'
        },
    ];

    getAdminMenu(): Menu {
        return this.getMenu(
            'admin',
            [
                {
                    name: 'parameters',
                    url: 'admin/parameters',
                    visible: () => this.user.isAdmin
                },
                {
                    name: 'classifiers',
                    url: 'admin/classifiers'
                },
                {
                    name: 'textTemplates',
                    url: 'admin/text-templates'
                },
                {
                    name: 'documentTemplates',
                    url: 'admin/document-templates'
                },
                {
                    name: 'users',
                    url: 'admin/users'
                },
                {
                    name: 'responsiblePersons',
                    url: 'admin/responsible-persons'
                },
                {
                    name: 'log',
                    url: 'admin/log',
                    visible: () => this.user.isAdmin
                },
                {
                    name: 'defaultValues',
                    url: 'admin/default-values'
                }
            ],
            true
        );
    }

    getUserMenu(): Menu {
        return this.getMenu(
            'user',
            [
                {
                    name: 'account',
                    url: 'account',
                    icon: 'account_circle'
                },
                {
                    name: 'changePassword',
                    url: 'account/change-password',
                    icon: 'lock',
                    visible: () => this.user.authType == AuthType.Forms && this.user.userType == UserType.Basic
                },
                {
                    name: 'logout',
                    url: 'auth/logout',
                    icon: 'logout'
                }
            ]
        );
    }

    getCategoryMenus(): Menu[] {
        return Object.keys(Category).map(c => {
            return new Menu(
                Category[c],
                [
                    ...this.categoryLinks.map((link: INavLink) => ({
                        name: link.name,
                        url: `${c.toLowerCase()}/${link.url}`,
                        text: this.app.translate(`nav.${link.name}`)
                    })),
                    this.getMenu(
                        'category.classifiers',
                        [
                            {
                                name: 'contractTemplates',
                                url: `${c.toLowerCase()}/contract-templates`,
                            },
                            {
                                name: 'drnRates',
                                url: `${c.toLowerCase()}/drn-rates`,
                            },
                            {
                                name: 'tariffRates',
                                url: `${c.toLowerCase()}/tariff-rates`
                            },
                            {
                                name: 'materialTypes',
                                url: `${c.toLowerCase()}/material-types`,
                            },
                            {
                                name: 'aaoMaterialTypes',
                                url: `${c.toLowerCase()}/aao-material-types`,
                            },
                            {
                                name: 'regenerationRates',
                                url: `${c.toLowerCase()}/regeneration-rates`,
                            }
                        ]
                    )
                ],
                Category[c]
            );
        });
    }

    getClientMenu(): Menu {
        return new Menu(
            'clients',
            [
                {
                    name: 'clients',
                    url: 'cl/clients'
                },
                {
                    name: 'contacts',
                    url: 'cl/contacts'
                },
                this.getMenu(
                    'category.classifiers',
                    [
                        {
                            name: 'minimalPayments',
                            url: 'minimal-payments',
                        }
                    ]
                )
            ]
        );
    }

    getEInvoiceMenu(): Menu {
        return this.getMenu(
            'eInvoices',
            [
                {
                    name: 'eInvoices',
                    url: 'e-invoices'
                }
            ]
        );
    }

    private getMenu(name: string, items: INavLink[], sort: boolean = false): Menu {
        const menu = new Menu(name, items.map(item => {
            return {
                ...item,
                text: this.app.translate(`nav.${item.name}`),
                visible: item.visible ? item.visible : () => true
            }
        }));

        if (sort)
            menu.items.sort((a, b) => a.text.localeCompare(b.text));

        return menu;
    }
}
