import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PagingParams } from '@core/paging-params';
import { ClientClient, ClientFilter } from '@zj/paka-client/clients';
import { IClientOption } from '@shared/data-access/client/models/client-option.model';
import { Category } from '@zj/paka-client';

@Component({
    selector: 'app-client-select-field',
    templateUrl: './client-select-field.component.html'
})
export class ClientSelectFieldComponent implements OnInit, ControlValueAccessor {
    constructor(
        private readonly clientService: ClientClient,
        private readonly router: Router,
        @Self() @Optional() private parent: NgControl
    ) {
        this.parent.valueAccessor = this;
    }

    @Input() baseCategories: Category[] = [];
    @Input() aaoCategories: Category[] = [];
    @Input() displayClass: string = 'valbox';
    @Input() enableCreation: boolean = false;
    @Input() hasEmptyOption: boolean = false;
    @Input() inline: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() label: string;
    @Input() loadOnInit: boolean = false;

    @Input() set initialOption(option: IClientOption | null) {
        if (option) {
            this.displayValue = option;
            this.options.push(option);
        }
    }

    value: number | null = null;
    displayValue: IClientOption | null = null;

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    protected options: IClientOption[] = [];

    private static lastLoadedOptions: IClientOption[] = [];

    private readonly clientPaging: PagingParams = new PagingParams({ page: 1, sort: 'name', sortDir: 'asc', take: 10 })

    ngOnInit(): void {
        if (this.loadOnInit)
            this.searchClients();
    }

    setValue(value: number): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: number): void {
        this.value = value;
        this.ensureOption();
        this.displayValue = this.options.find(clientOption => clientOption.id == this.value);
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    protected createClient(event: any): void {
        event.stopPropagation();

        window.open(this.router.serializeUrl(
            this.router.createUrlTree(['/cl/clients/create'])
        ));
    }

    protected searchClients(name: string | null = null): void {
        const filter: ClientFilter = new ClientFilter();
        filter.baseCategories = this.baseCategories;
        filter.aaoCategories = this.aaoCategories;
        filter.names = [name];

        this.clientService.get(this.clientPaging, filter).subscribe({
            next: response => {
                this.options = response.items;
                ClientSelectFieldComponent.lastLoadedOptions = this.options;
            }
        });
    }

    protected viewClient(event: any): void {
        event.stopPropagation();

        window.open(this.router.serializeUrl(
            this.router.createUrlTree(['/cl/clients/edit/', this.value])
        ));
    }

    private ensureOption(): void {
        if (this.options.find(client => client.id == this.value))
            return;

        let cached: IClientOption | undefined = ClientSelectFieldComponent.lastLoadedOptions.find(client => client.id == this.value)

        if (cached) {
            this.options.push(cached);
            return;
        }

        if (this.value)
            this.clientService.getById(this.value).subscribe(client => {
                this.options = [<IClientOption>{
                    id: client.id,
                    name: client.name
                }];
            });
    }

    private onChange = (value: any) => { };
    private onTouch = () => { };
}
