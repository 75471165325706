import { Component, Input } from '@angular/core';
import { INavLink } from '../../data-access/models/nav-link.model';

@Component({
    selector: 'app-header-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss', '../header.component.scss']
})
export class HeaderMenuComponent {
    @Input() items: INavLink[] = [];
    @Input() style: string;
    @Input() color: string;
    @Input() activeStyle: string;

    getVisible(items: INavLink[]): INavLink[] {
        return items && items.filter((t) => !t.visible || t.visible(t));
    }
}
