import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, catchError, of, throwError, first } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AppSettings } from '../app/models/app-settings';
import { User } from './models/user';
import { IAuthConfiguration } from './models/auth-configuration.model';
import { ILoginResponse } from './models/login-response.model';
import { ILogoutResponse } from './models/logout-response.model';
import { IPasswordSettings } from './models/password-settings.model';
import { IUserData } from './models/user-data.model';
import { Router } from '@angular/router';

const STORAGE_KEYS = {
    user: 'user',
    accessToken: 'auth.token',
    accessTokenExpires: 'auth.tokenExpires',
    refreshToken: 'auth.refreshToken',
    getAuth: 'auth.get',
    setAuth: 'auth.set',
    endAuth: 'auth.end'
};

export const anonymous = new User();

/**
 * Authentication service
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private router: Router
    ) {
        this.storageKey = Date.now().toString();
        this.setupListeners();
    }

    static readonly STORAGE_KEY_PREFIX: string = 'backOffice';

    get apiUrl(): string {
        return `${this.appSettings.apiUrl}/auth`;
    }
    get refreshUrl(): string {
        return `${this.apiUrl}/refresh`;
    }
    get loginUrl(): string {
        return `${this.apiUrl}/login`;
    }
    get logoutUrl(): string {
        return `${this.apiUrl}/logout`;
    }

    private _currentUser: User;
    get currentUser(): User {
        return this._currentUser || anonymous;
    }

    private _accessToken: string;
    get accessToken(): string {
        return this._accessToken;
    }

    private _refreshToken: string;
    get refreshToken(): string {
        return this._refreshToken;
    }

    get isAuthenticated(): boolean {
        return this.currentUser.isAuthenticated;
    }

    private storage = sessionStorage;
    private storageKey: string;
    private accessTokenExpires?: Date;

    private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(anonymous);

    accessTokenExpired(): boolean {
        return !this.accessTokenExpires || (this.accessTokenExpires.getTime() - new Date().getTime()) / 60000 < 1;
    }

    authenticate(callback: (user: User) => void) {
        if (this.isAuthenticated) {
            callback(this.currentUser);

            return;
        }

        // get from the current storage
        try {
            const user = this.createUser(<IUserData>JSON.parse(this.storage.getItem(AuthService.getStorageKey(STORAGE_KEYS.user))));

            if (user.id) {
                this.setUser(user, {
                    token: this.storage.getItem(AuthService.getStorageKey(STORAGE_KEYS.accessToken)),
                    refreshToken: this.storage.getItem(AuthService.getStorageKey(STORAGE_KEYS.refreshToken)),
                    expires: new Date(+this.storage.getItem(AuthService.getStorageKey(STORAGE_KEYS.accessTokenExpires)))
                });
            }
        } catch (err) {
            this.clearSession();
        }

        if (this.isAuthenticated) {
            callback(this.currentUser);

            return;
        }

        // get from an other browser tab
        this.requestFromOtherSession()
            .pipe(first())
            .subscribe(user => callback(user));
    }

    getUser(): Observable<User> {
        return this.userSubject.asObservable();
    }

    getConfiguration(): Observable<IAuthConfiguration> {
        const url = `${this.apiUrl}/configuration`;
        return this.http.get<IAuthConfiguration>(url);
    }

    getPasswordSettings(): Observable<IPasswordSettings> {
        const url = `${this.apiUrl}/passwordSettings`;

        return this.http.get<IPasswordSettings>(url);
    }

    loginByCode(code: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this.loginUrl, { code }, { withCredentials: true }).pipe(
            tap((data) => {
                this.parseLoginResponse(data);
                return data;
            })
        );
    }

    loginByUserNameAndPassword(username: string, password: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this.loginUrl, { username, password }, { withCredentials: true }).pipe(
            tap((data) => {
                this.parseLoginResponse(data);
                return data;
            })
        );
    }

    logout(): Observable<ILogoutResponse> {
        return this.http.post<ILogoutResponse>(this.logoutUrl, null, { withCredentials: true })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status == 401) {
                        return of({});
                    }

                    return throwError(err);
                }),
                tap((data) => {
                    this.clearAllSessions();

                    return data;
                })
            );
    }

    /**
     * Recover user password.
     *
     * @param userName User name of the user for which to recover password.
     */
    recoverPassword(userName: string): Observable<void> {
        const url: string = `${this.apiUrl}/password/recover`;

        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<void>(url, JSON.stringify(userName), {
            headers: headers,
            withCredentials: true
        });
    }

    /**
     * Refresh user authentication.
     *
     * @param emit Emit refreshed user data to the user observables.
     *
     * @returns Access token.
     */
    refresh(emit: boolean = true): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return <Observable<string>>this.http.post<ILoginResponse>(this.refreshUrl, JSON.stringify(this._refreshToken), {
            headers: headers,
            withCredentials: true
        }).pipe(
            map((data) => {
                this.parseLoginResponse(data, emit);

                return data.accessToken;
            })
        );
    }

    resetPassword(password: string): Observable<any> {
        const url = `${this.apiUrl}/password`;

        return this.http.post(url, { password });
    }

    resetPasswordWithSecret(password: string, secret: string): Observable<any> {
        const url = `${this.apiUrl}/password`;

        return this.http.post(url + '/secret', { password, secret });
    }

    private static getStorageKey(storageKey: string): string {
        return `${AuthService.STORAGE_KEY_PREFIX}${storageKey}`;
    }

    /**
     * Clear all sessions.
     *
     * Clears sessions in all tabs.
     */
    private clearAllSessions() {
        this.clearSession();

        this.dispatchEvent(AuthService.getStorageKey(STORAGE_KEYS.endAuth), '1');
    }

    /**
     * Clear current session.
     *
     * Clears only the session in the active/current tab.
     */
    private clearSession() {
        this.setUser(anonymous, { token: undefined, refreshToken: undefined, expires: new Date() });
    }

    private parseLoginResponse(data: ILoginResponse, emit: boolean = true) {
        const user = this.createUser(data);

        this.setUser(user, {
            token: data.accessToken,
            refreshToken: data.refreshToken,
            expires: new Date(data.accessTokenExpires)
        }, emit);

        this.shareAuth();
    }

    private createUser(data: IUserData): User {
        const user = new User();

        if (data) {
            user.userName = data.userName;
            user.person = data.person;
            user.userRole = data.userRole;
            user.userType = data.userType;
            user.id = data.id;
            user.authType = data.authType;
            user.mustResetPassword = data.mustResetPassword;
        }

        return user;
    }

    private setUser(user: User, accessToken: { token: string; refreshToken: string; expires?: Date }, emit: boolean = true) {
        if (user) {
            this.storage.setItem(AuthService.getStorageKey(STORAGE_KEYS.user), JSON.stringify(user));
            this.storage.setItem(AuthService.getStorageKey(STORAGE_KEYS.accessToken), accessToken.token);
            this.storage.setItem(AuthService.getStorageKey(STORAGE_KEYS.accessTokenExpires), accessToken.expires.getTime().toString());
            this.storage.setItem(AuthService.getStorageKey(STORAGE_KEYS.refreshToken), accessToken.refreshToken);
        } else {
            this.storage.removeItem(AuthService.getStorageKey(STORAGE_KEYS.user));
            this.storage.removeItem(AuthService.getStorageKey(STORAGE_KEYS.accessToken));
            this.storage.removeItem(AuthService.getStorageKey(STORAGE_KEYS.accessTokenExpires));
            this.storage.removeItem(AuthService.getStorageKey(STORAGE_KEYS.refreshToken));
        }

        this._currentUser = user;
        this._accessToken = accessToken.token;
        this._refreshToken = accessToken.refreshToken;

        this.accessTokenExpires = accessToken.expires;

        if (emit) {
            this.userSubject.next(user);
        }
    }

    private requestFromOtherSession(): Observable<User> {
        const subj = new Subject<User>();
        const getKey = `${AuthService.getStorageKey(STORAGE_KEYS.getAuth)}${this.storageKey}`;

        this.dispatchEvent(getKey, '1');

        setTimeout(() => {
            subj.next(this.isAuthenticated ? this.currentUser : anonymous);
        }, 100);

        return subj.asObservable();
    }

    /**
     * Setup dispatched auth event listeners.
     */
    private setupListeners() {
        window.addEventListener('storage', (event) => {
            if ((event.key || '').indexOf(AuthService.getStorageKey(STORAGE_KEYS.getAuth)) === 0)
                this.handleGetAuthEvent(event);
            else if (event.key === AuthService.getStorageKey(STORAGE_KEYS.setAuth))
                this.handleSetAuthEvent(event);
            else if (event.key === AuthService.getStorageKey(STORAGE_KEYS.endAuth))
                this.handleEndAuthEvent(event);
        });
    }

    /**
     * Dispatch an auth related event.
     */
    private dispatchEvent(event: string, data: string) {
        localStorage.setItem(event, data);
        localStorage.removeItem(event);
    }

    /**
     * Share current authentication with other tabs.
     */
    private shareAuth() {
        this.dispatchEvent(
            AuthService.getStorageKey(STORAGE_KEYS.setAuth),
            JSON.stringify({
                user: this.storage.getItem(AuthService.getStorageKey(STORAGE_KEYS.user)),
                token: this.accessToken,
                refreshToken: this.refreshToken,
                expires: this.accessTokenExpires
            })
        );
    }

    private handleGetAuthEvent(event: StorageEvent): void {
        this.shareAuth();
    }

    private handleSetAuthEvent(event: StorageEvent): void {
        if (!event.newValue)
            return;

        const data = JSON.parse(event.newValue);
        const user = this.createUser(<IUserData>JSON.parse(data.user));

        this.setUser(user, {
            token: data.token,
            refreshToken: data.refreshToken,
            expires: new Date(data.expires)
        });
    }

    private handleEndAuthEvent(event: StorageEvent): void {
        if (!this.isAuthenticated)
            return;

        this.clearSession();

        const currentUrl: string = this.router.url;

        this.router.navigate(['auth', 'login'],
            {
                queryParams: { returnUrl: currentUrl }
            }
        );
    }
}
