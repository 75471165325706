import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { Util } from '@zj/paka-client';

@Component({
    selector: 'app-attachment-field',
    templateUrl: './attachment-field.component.html',
    styleUrls: ['./attachment-field.component.scss']
})
export class AttachmentFieldComponent implements ControlValueAccessor, OnInit {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() acceptExtensions: string;

    @Output() download: EventEmitter<any> = new EventEmitter();

    value: File[];
    isDisabled: boolean;
    isRequired: boolean;

    get errors(): ValidationErrors | null {
        return this.parent?.control?.errors || null;
    }

    readonly prettySize = Util.prettyFileSize;

    ngOnInit(): void {
        this.setRequiredState();
    }

    setValue(value: File[]): void {
        this.value = value;
        this.onChange(this.value);
        this.validateValue(this.value);
    }

    writeValue(value: File[]): void {
        this.value = value;
    }

    registerOnChange(fn: (value: File[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    setRequiredState(): void {
        this.isRequired = this.parent?.control?.hasValidator(Validators.required)
            || (this.parent?.control?.validator?.({} as AbstractControl)?.['required'] ?? false);
    }

    onBlur(): void {
        this.onTouch();
    }

    protected onFileSelect(event: any): void {
        const files = [...event.target.files];

        this.setValue(files);
    }

    protected removeFile(file: File): void {
        const files: File[] = [...this.value];

        Util.removeItemFromArray(files, file)

        this.setValue(files);
    }

    private validateValue(value: File[]): void {
        if (value == null)
            return;

        const errors: ValidationErrors = {};

        for (let i = 0; i < value.length; i++) {
            const file: File = value[i];

            if (this.acceptExtensions) {
                const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();

                if (!this.acceptExtensions.includes(extension) && !this.acceptExtensions.includes('.' + extension))
                    errors.fileExtension = { value: this.acceptExtensions };
            }
        }

        if (Object.keys(errors).length)
            this.parent.control.setErrors(errors)
    }

    private onChange = (value: File[]) => { };
    private onTouch = () => { };
}
