import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMenuComponent } from './header/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { MainPageRoutingModule } from './main-routing.module';
import { SubHeaderComponent } from './header/sub-header/sub-header.component';
import { SubHeaderLinkComponent } from './header/sub-header/sub-header-link/sub-header-link.component';
import { SubHeaderMenuComponent } from './header/sub-header/sub-header-menu/sub-header-menu.component';

@NgModule({
    imports: [
        MainPageRoutingModule,
        CommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http);
                },
                deps: [HttpClient]
            }
        }),
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule
    ],
    declarations: [
        HeaderComponent,
        HeaderMenuComponent,
        SubHeaderComponent,
        SubHeaderMenuComponent,
        SubHeaderLinkComponent,
        HomeComponent,
        FooterComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent
    ]
})
export class MainPageModule { }
