import { Component } from '@angular/core';
import { NavService } from '../data-access/nav.service';
import { Menu } from '../data-access/models/menu';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    constructor(
        private nav: NavService
    ) { }

    menus: Menu[] = [
        ...this.nav.getCategoryMenus(),
        this.nav.getClientMenu(),
    ];
}
