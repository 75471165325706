import { Injectable } from "@angular/core";
import { TableColumnLayout } from "./table-column-layout";
import { ITableColumnSettingsParameters } from "./table-column-settings";
import { MatLegacyColumnDef as MatColumnDef } from "@angular/material/legacy-table";
import { TableColumn } from "./table-column";

export interface ITableColumnLayoutCacheEntry {
    name: string;
    index: number;
    settings: ITableColumnSettingsParameters;
}

@Injectable({ providedIn: 'root' })
export class TableColumnLayoutStorage {
    /**
     * Get a table column layout from storage.
     *
     * @param key Stored table column access key.
     * @param columns A list of table colums.
     *
     * @returns A new table column layout from sotrage if found; otherwise null.
     */
    get(key: string, columns: TableColumn[]): TableColumnLayout | null {

        const cacheEntries: ITableColumnLayoutCacheEntry[] = JSON.parse(localStorage.getItem(key));

        if (!cacheEntries)
            return null;

        const layout = new TableColumnLayout([]);

        columns.forEach(column => {
            const cacheEntry: ITableColumnLayoutCacheEntry = cacheEntries.find(entry => entry.name == column.columnDef.name);

            column.settings.apply(cacheEntry.settings);

            layout.addColumn(column, cacheEntry.index);
        });

        return layout;
    }

    restore(key: string, layout: TableColumnLayout): void {
        const cacheEntries: ITableColumnLayoutCacheEntry[] = JSON.parse(localStorage.getItem(key));

        if (!cacheEntries)
            return;

        layout.sortedColumns.forEach(column => {
            const cacheEntry: ITableColumnLayoutCacheEntry = cacheEntries.find(entry => entry.name == column.columnDef.name);

            if (!cacheEntry)
                return;

            column.settings.apply(cacheEntry.settings);

            layout.setIndex(column, cacheEntry.index);
        });
    }

    /**
     * Remove a table column layout from storage.
     *
     * @param key
     */
    remove(key: string): void {
        localStorage.removeItem(key);
    }

    /**
     * Safely store current table settings in localstorage.
     *
     * @param key Stored table column access key.
     * @param layout Table column layout to store.
     */
    store(key: string, layout: TableColumnLayout): void {
        try {
            localStorage.setItem(key, JSON.stringify(layout.sortedColumns.map((item, index) => <ITableColumnLayoutCacheEntry>{
                index: index,
                name: item.columnDef.name,
                settings: item.settings.parameters
            })));
        } catch (error) {
            console.error(`Failed to persist table settings for ${key}.`, error)
        }
    }
}
