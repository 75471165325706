import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { FileType } from '@zj/paka-client';

@Component({
    selector: 'app-file-edit-field',
    templateUrl: './file-edit-field.component.html',
    styleUrls: ['./file-edit-field.component.scss']
})
export class FileEditFieldComponent implements ControlValueAccessor {
    constructor(@Self() @Optional() private parent: NgControl) {
        this.parent.valueAccessor = this;
    }

    @Input() isEdit: boolean = false;
    @Input() inline: boolean = false;
    @Input() label: string;
    @Input() displayClass: string = 'valbox';
    @Input() types: FileType[];
    @Input() acceptExtensions: string;
    @Input() maxSize: number;

    @Output() download: EventEmitter<FileType | null> = new EventEmitter();

    displayValue: File;

    get control(): FormControl {
        return this.parent.control as FormControl;
    }

    setValue(value: File): void {
        this.writeValue(value);
        this.onChange(value);
    }

    writeValue(value: File): void {
        this.displayValue = value;
    }

    registerOnChange(fn: (value: File) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private onChange = (value: File) => { };
    private onTouch = () => { };
}
