<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <input matInput [ngModel]="value" (ngModelChange)="setValue($event)" [disabled]="isDisabled" [required]="isRequired"
        [maxLength]="maxlength" (blur)="onBlur()" [matAutocomplete]="auto" [errorStateMatcher]="errorStateMatcher" />

    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option">
            <ng-container [ngTemplateOutlet]="optionTemplate ? optionTemplate : defaultTemplate"
                [ngTemplateOutletContext]="{option: option, value: option}">
            </ng-container>
        </mat-option>
    </mat-autocomplete>

    <button *ngIf="clearEnabled && value" mat-icon-button matSuffix (click)="setValue(null)" type="button">
        <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>

<ng-template #defaultTemplate let-value="value">{{ value }}</ng-template>
