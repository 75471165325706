<div class="site-sub-header">
    <div class="container">
        <ul *ngIf="getVisible(menu.items).length" class="sub-nav">
            <li *ngFor="let n of getVisible(menu.items)" class="sub-nav-item">
                <app-sub-header-link *ngIf="!isMenu(n)" [link]="n" [colorName]="menu.name"></app-sub-header-link>
                <app-sub-header-menu *ngIf="isMenu(n)" [menu]="getMenu(n)" [colorName]="menu.name"
                    (toggleMenu)="toggleMenu.emit($event)">
                </app-sub-header-menu>
            </li>
        </ul>

        <div class="sub-header-title color-{{ menu.name }}">
            <ng-container *ngIf="menu.text">
                {{ "nav." + menu.name | translate }}
            </ng-container>
        </div>
    </div>
</div>
