import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { ServiceDate } from '@core/service-date';

@Component({
    selector: 'app-service-date-field-input',
    templateUrl: './service-date-field-input.component.html',
    styleUrls: ['./service-date-field-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: ServiceDateFieldInputComponent }],
    host: {
        '[class.example-floating]': 'shouldLabelFloat',
        '[id]': 'id',
    },
})
export class ServiceDateFieldInputComponent implements ControlValueAccessor, MatFormFieldControl<ServiceDate>, OnDestroy {
    constructor(
        private _formBuilder: FormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    @ViewChild('year') yearInput: HTMLInputElement;

    static nextId = 0;

    parts = this._formBuilder.group({
        year: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
        month: [1, [Validators.required]],
    });
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'service-date-input';
    id = `service-date-input-${ServiceDateFieldInputComponent.nextId++}`;
    onChange = (_: any) => { };
    onTouched = () => { };

    get empty() {
        const {
            value: { year, month },
        } = this.parts;

        return !year;
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input('aria-describedby') userAriaDescribedBy: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
        return this._required || this.ngControl?.control?.hasValidator(Validators.required);
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): ServiceDate | null {
        if (!this.parts.valid)
            return null;

        const {
            value: { year, month },
        } = this.parts;

        const serviceDateString = `${year}/${month}`;

        return ServiceDate.isValid(serviceDateString)
            ? new ServiceDate(serviceDateString)
            : null;
    }
    set value(serviceDate: ServiceDate | null) {
        const year: string = serviceDate ? serviceDate.year.toString() : '';
        const month: number = serviceDate ? serviceDate.month : 1;

        this.parts.setValue({ year, month });
        this.stateChanges.next();
    }

    get errorState(): boolean {
        if (!this.ngControl.control.touched)
            return false;

        if (this.ngControl.control.errors != null)
            return true;

        const {
            value: { year, month },
        } = this.parts;

        if (!year)
            return true;

        if (!this.parts.valid)
            return true;

        let result: boolean = !ServiceDate.isValid(`${year}/${month}`);

        return result;
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.service-date-input-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick() {
        this._focusMonitor.focusVia(this.yearInput, 'program');
    }

    writeValue(serviceDate: ServiceDate | null): void {
        this.value = serviceDate;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
