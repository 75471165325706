import { Component, Input, ViewChild, ElementRef, OnDestroy, Optional, Inject, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: FileInputComponent }],
    host: {
        '[id]': 'id',
    },
})
export class FileInputComponent implements ControlValueAccessor, MatFormFieldControl<File>, OnDestroy {
    constructor(
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    @ViewChild('proxyInput') proxyInput: HTMLInputElement;

    @Input('aria-describedby') userAriaDescribedBy: string;
    @Input() multiple = false;
    @Input() acceptExtensions: string = '';
    @Input() hasErrors: boolean = false;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string = 'file.placeholder';

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _disabled = false;

    get empty() {
        return this.value == null;
    }

    get errorState(): boolean {
        return this.touched && this.hasErrors;
    }

    value: File;
    shouldLabelFloat = true;
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'file-input';
    id = `file-input-${FileInputComponent.nextId++}`;

    private static nextId = 0;

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouch();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.file-input-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick() {
        this._focusMonitor.focusVia(this.proxyInput, 'program');
    }

    writeValue(file: File | null): void {
        this.value = file;
    }

    registerOnChange(fn: (value: File) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    protected onFileSelect(event: any): void {
        const file = event.target.files[0];
        if (!file) return;

        this.value = file;
        this.onChange(this.value);

        event.target.value = null;
    }

    private onChange = (value: File) => { };
    private onTouch = () => { };
}
