import { Pipe } from '@angular/core';

@Pipe({
    name: 'orderby'
})
export class OrderByPipe {
    transform(value: any[], field: string): any {
        if ((value || '').length <= 1 || !field) {
            return value;
        }

        if (field.startsWith('-')) {
            field = field.substring(1);

            if (typeof value[0][field] === 'string' || value[0][field] instanceof String) {
                return [...value].sort((a, b) => b[field].localeCompare(a[field]));
            }

            return [...value].sort((a, b) => b[field] - a[field]);
        } else {
            if (typeof value[0][field] === 'string' || value[0][field] instanceof String) {
                return [...value].sort((a, b) => -b[field].localeCompare(a[field]));
            }

            return [...value].sort((a, b) => a[field] - b[field]);
        }
    }
}
