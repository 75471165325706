<app-file-field *ngIf="isEdit" [inline]="inline" [label]="label" (download)="download.emit()"
    [acceptExtensions]="acceptExtensions" [maxSize]="maxSize" [formControl]="control"
    (ngModelChange)="setValue($event)">
</app-file-field>

<app-value-field *ngIf="!isEdit" [displayClass]="displayClass" [displayTemplate]="fileTemplate" [inline]="inline"
    [label]="label" [value]="displayValue">
</app-value-field>

<ng-template #fileTemplate let-value="value">
    <div class="value-container">
        <ng-container *ngIf="types && types.length > 0">
            <div class="value-text">
                {{value?.name.replace('.' + value?.name.split('.').pop(), '')}}
            </div>
            <div *ngIf="value">
                <button mat-icon-button *ngFor="let type of types" (click)="download.emit(type)">
                    <mat-icon class="fiv-sqo fiv-icon-blank fiv-icon-{{type}}">
                    </mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="!types || types.length === 0">
            <div style="overflow: hidden;" class="value-text">
                {{value?.name}}
            </div>
            <div *ngIf="value">
                <button mat-icon-button (click)="download.emit()">
                    <mat-icon class="fiv-sqo fiv-icon-blank fiv-icon-{{(value?.name || '.blank').split('.').pop()}}">
                    </mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
</ng-template>
